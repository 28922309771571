import { WidgetLazyLoader } from '../WidgetLazyLoader/WidgetLazyLoader';
import React from 'react';
import { configuration } from 'config/constants';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-web-analytics': {
                'site-id': string;
                'base-url'?: string;
            };
        }
    }
}

export const MatomoWidget: React.FC = () => {
    return (
        <WidgetLazyLoader script={`${configuration.sharedWidgetBaseUrl}/widgets/sgwt-web-analytics/v4/sgwt-web-analytics.js`}>
            <sgwt-web-analytics site-id={configuration.matomoSiteId} base-url={configuration.matomoUrl} />
        </WidgetLazyLoader>
    );
};
