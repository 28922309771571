import { ApiRequest, Reducer, mergeAllRecords, mergeRecords } from 'store';
import { SearchRecipientsResult } from 'services/ApiService/ClientSubscriptionList/ClientSubscriptionListApiClient';

type SearchRecordType = Record<string, { byKey: Record<string, ApiRequest<SearchRecipientsResult>> }>;

export const initialState: {
    search: SearchRecordType,
    history: SearchRecordType,
    isLocking: boolean,
    isUnlocking: boolean,
    isAdding: boolean,
    isRemoving: boolean,
    isDownloadingHistory: boolean,
} = {
    search: {},
    history: {},
    isLocking: false,
    isUnlocking: false,
    isAdding: false,
    isRemoving: false,
    isDownloadingHistory: false,
} as const;

export type RecipientState = typeof initialState;

export const RecipientReducer: Reducer<RecipientState> = (state = initialState, action) => {
    switch (action.type) {
        case '@RECIPIENT/FETCH_SEARCH_RECIPIENTS':
            return {
                ...state,
                search: mergeSearchRecipientState(state.search, action.payload.listId, action.payload.searchKey, {
                    state: 'isFetching',
                }),
            } satisfies RecipientState;
        case '@RECIPIENT/FETCH_SEARCH_RECIPIENTS_SUCCESS':
            return {
                ...state,
                search: mergeSearchRecipientState(state.search, action.payload.listId, action.payload.searchKey, {
                    state: 'valid',
                    data: action.payload.data,
                }),
            } satisfies RecipientState;
        case '@RECIPIENT/FETCH_SEARCH_RECIPIENTS_FAILURE':
            return {
                ...state,
                search: mergeSearchRecipientState(state.search, action.payload.listId, action.payload.searchKey, {
                    state: 'error',
                }),
            } satisfies RecipientState;

        case '@RECIPIENT/FETCH_HISTORY_RECIPIENTS':
            return {
                ...state,
                history: mergeSearchRecipientState(state.history, action.payload.listId, action.payload.searchKey, {
                    state: 'isFetching',
                }),
            } satisfies RecipientState;
        case '@RECIPIENT/FETCH_HISTORY_RECIPIENTS_SUCCESS':
            return {
                ...state,
                history: mergeSearchRecipientState(state.history, action.payload.listId, action.payload.searchKey, {
                    state: 'valid',
                    data: action.payload.data,
                }),
            } satisfies RecipientState;
        case '@RECIPIENT/FETCH_HISTORY_RECIPIENTS_FAILURE':
            return {
                ...state,
                history: mergeSearchRecipientState(state.history, action.payload.listId, action.payload.searchKey, {
                    state: 'error',
                }),
            } satisfies RecipientState;

        case '@RECIPIENT/ADD_RECIPIENT':
            return {
                ...state,
                isAdding: true,
            } satisfies RecipientState;
        case '@RECIPIENT/ADD_RECIPIENT_SUCCESS':
        case '@RECIPIENT/ADD_RECIPIENT_FAILURE':
            return {
                ...state,
                isAdding: false,
            } satisfies RecipientState;

        case '@RECIPIENT/REMOVE_RECIPIENT':
            return {
                ...state,
                isRemoving: true,
            } satisfies RecipientState;
        case '@RECIPIENT/REMOVE_RECIPIENT_SUCCESS':
        case '@RECIPIENT/REMOVE_RECIPIENT_FAILURE':
            return {
                ...state,
                isRemoving: false,
            } satisfies RecipientState;

        case '@RECIPIENT/OUTDATE_RECIPIENT':
            return {
                ...state,
                search: mergeAllSearchRecipientState(state.search, action.payload.listId, {
                    state: 'outdated',
                }),
                history: mergeAllSearchRecipientState(state.history, action.payload.listId, {
                    state: 'outdated',
                }),
            } satisfies RecipientState;

        case '@RECIPIENT/LOCK_RECIPIENT':
            return {
                ...state,
                isLocking: true,
            } satisfies RecipientState;
        case '@RECIPIENT/LOCK_RECIPIENT_SUCCESS':
        case '@RECIPIENT/LOCK_RECIPIENT_FAILURE':
            return {
                ...state,
                isLocking: false,
            } satisfies RecipientState;

        case '@RECIPIENT/UNLOCK_RECIPIENT':
            return {
                ...state,
                isUnlocking: true,
            } satisfies RecipientState;
        case '@RECIPIENT/UNLOCK_RECIPIENT_SUCCESS':
        case '@RECIPIENT/UNLOCK_RECIPIENT_FAILURE':
            return {
                ...state,
                isUnlocking: false,
            } satisfies RecipientState;

        case '@RECIPIENT/FETCH_EXPORT_RECIPIENTS':
            return {
                ...state,
                isDownloadingHistory: true,
            } satisfies RecipientState;
        case '@RECIPIENT/FETCH_EXPORT_RECIPIENTS_SUCCESS':
        case '@RECIPIENT/FETCH_EXPORT_RECIPIENTS_FAILURE':
            return {
                ...state,
                isDownloadingHistory: false,
            } satisfies RecipientState;

        default:
            return state;
    }
};

const mergeSearchRecipientState = (statePart: SearchRecordType, listId: string, key: string, byKeyStatePart: ApiRequest<SearchRecipientsResult>): SearchRecordType => {
    return {
        ...statePart,
        ...mergeRecords(statePart, listId, {
            byKey: {
                ...mergeRecords(statePart[listId]?.byKey ?? {}, key, {
                    ...byKeyStatePart,
                }),
            },
        }),
    };
};

const mergeAllSearchRecipientState = (statePart: SearchRecordType, listId: string, byKeyStatePart: ApiRequest<SearchRecipientsResult>): SearchRecordType => {
    return {
        ...statePart,
        ...mergeRecords(statePart, listId, {
            byKey: {
                ...mergeAllRecords(statePart[listId]?.byKey || {}, byKeyStatePart),
            },
        }),
    };
};