export const mergeRecords = <T extends number | string, V>(statePart: Record<T, V>, key: T, newSubStatePart: V): Record<T, V> => {
    return {
        ...statePart,
        [key]: {
            ...statePart[key],
            ...newSubStatePart,
        },
    };
};

export const mergeAllRecords = <T extends number | string, V>(statePart: Record<T, V>, newSubStatePart: V): Record<T, V> => {
    return {
        ...(Object.keys(statePart || {}) as Array<keyof typeof statePart>)
            .reduce((prev, curr) => ({
                ...prev,
                [curr]: {
                    ...statePart[curr],
                    ...newSubStatePart,
                },
            }), {} as Record<T, V>),
    };
};