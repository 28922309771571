import { GetRecipientIntegrationDetailsResponse, RecipientFileIntegrationLightModel } from 'services/ApiService/ClientSubscriptionList/ClientSubscriptionListApiClient';
import { ApiRequest, Reducer, mergeRecords } from 'store';

export const initialState: {
    items: Record<string, ApiRequest<RecipientFileIntegrationLightModel[]>>,
    details: Record<number, ApiRequest<GetRecipientIntegrationDetailsResponse>>,
} = {
    items: {},
    details: {},
} as const;

export type RecipientIntegrationState = typeof initialState;

export const RecipientIntegrationReducer: Reducer<RecipientIntegrationState> = (state = initialState, action) => {
    switch (action.type) {
        case '@RECIPIENT_INTEGRATION/FETCH_RECIPIENT_INTEGRATIONS':
            return {
                ...state,
                items: mergeRecords(state.items, action.payload.listId, {
                    state: 'isFetching',
                }),
            } satisfies RecipientIntegrationState;
        case '@RECIPIENT_INTEGRATION/FETCH_RECIPIENT_INTEGRATIONS_SUCCESS':
            return {
                ...state,
                items: mergeRecords(state.items, action.payload.listId, {
                    state: 'valid',
                    data: action.payload.data,
                }),
            } satisfies RecipientIntegrationState;
        case '@RECIPIENT_INTEGRATION/FETCH_RECIPIENT_INTEGRATIONS_FAILURE':
            return {
                ...state,
                items: mergeRecords(state.items, action.payload.listId, {
                    state: 'error',
                }),
            } satisfies RecipientIntegrationState;
        case '@RECIPIENT_INTEGRATION/FETCH_RECIPIENT_INTEGRATIONS_OUTDATE':
            return {
                ...state,
                items: mergeRecords(state.items, action.payload.listId, {
                    state: 'outdated',
                }),
            } satisfies RecipientIntegrationState;

        case '@RECIPIENT_INTEGRATION/FETCH_RECIPIENT_INTEGRATION_DETAILS':
            return {
                ...state,
                details: mergeRecords(state.details, action.payload.integrationId, {
                    state: 'isFetching',
                }),
            } satisfies RecipientIntegrationState;
        case '@RECIPIENT_INTEGRATION/FETCH_RECIPIENT_INTEGRATION_DETAILS_SUCCESS':
            return {
                ...state,
                details: mergeRecords(state.details, action.payload.integrationId, {
                    state: 'valid',
                    data: action.payload.data,
                }),
            } satisfies RecipientIntegrationState;
        case '@RECIPIENT_INTEGRATION/FETCH_RECIPIENT_INTEGRATION_DETAILS_FAILURE':
            return {
                ...state,
                details: mergeRecords(state.details, action.payload.integrationId, {
                    state: 'error',
                }),
            } satisfies RecipientIntegrationState;

        default:
            return state;
    }
};
