import React from 'react';
import sgLogoGlyph from 'images/sg_logo_glyph.svg';
import { AccountCenter } from 'components/layout/accountCenter/AccountCenter';
import { Link } from 'react-router-dom';

export const Header = () => (
    <div className="header">
        <nav className="navbar navbar-lg mb-5 border-bottom border-top">
            <div className="navbar-title">
                <Link to="/" className="navbar-title-link">
                    <div className="navbar-logo">
                        <img src={sgLogoGlyph} alt="SG logo Glyph" />
                    </div>
                    <div className="navbar-title-divider"></div>
                    <div className="navbar-service-name">Subscription list</div>
                </Link>
            </div>
            <AccountCenter />
        </nav>
    </div>
);
