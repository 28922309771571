import React from 'react';
import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader/WidgetLazyLoader';
import { configuration, getEnvironment } from 'config/constants';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-account-center': any;
        }
    }
}

export const AccountCenter: React.FC = () => {
    return (
        <WidgetLazyLoader script={`${configuration.sharedWidgetBaseUrl}/widgets/sgwt-account-center/v4/sgwt-account-center.js`}>
            <sgwt-account-center
                id="sgwtAccountCenter"
                authentication="sg-connect-v2"
                mode="sg-markets"
                environment={getEnvironment()}
                producer-code="sgm_myservices"
            />
        </WidgetLazyLoader>
    );
};
