import { Reducer } from 'store';

export const initialState: Record<string, number> = {} as const;

export type RecipientCountState = typeof initialState;

// Recipient count can be updated when a new recipient is added and remove, or when an integration from file is in progress
// This count is also available in multiple elements : in search, in subscriptionList, in recipientSearch
// To always have an updated version, this count is stored separately from other elements
export const RecipientCountReducer: Reducer<RecipientCountState> = (state = initialState, action) => {
    switch (action.type) {
        case '@SUBSCRIPTION/FETCH_SEARCH_LIST_SUCCESS':
            return {
                ...state,
                ...action.payload.data
                    .reduce((prev, curr) => { prev[curr.id] = curr.recipientCount; return prev; }, {} as typeof state),
            } satisfies RecipientCountState;
        case '@SUBSCRIPTION/FETCH_ONE_LIST_SUCCESS':
            return {
                ...state,
                [action.payload.id]: action.payload.data.recipientCount,
            } satisfies RecipientCountState;
        case '@RECIPIENT/ADD_RECIPIENT':
            return {
                ...state,
                [action.payload.listId]: state[action.payload.listId] + 1,
            } satisfies RecipientCountState;
        case '@RECIPIENT/REMOVE_RECIPIENT':
            return {
                ...state,
                [action.payload.listId]: state[action.payload.listId] - 1,
            } satisfies RecipientCountState;

        case '@RECIPIENT_COUNT/RECIPIENT_COUNT_SUCCESS':
            return {
                ...state,
                [action.payload.listId]: action.payload.totalCount,
            } satisfies RecipientCountState;

        default:
            return state;
    }
};
