export const createSetUserInfoAction = (icid: string, email: string) => {
    return {
        type: '@USER_INFO/SET',
        payload: {
            icid,
            email,
        },
    } as const;
};

export type UserInfoAction =
    | ReturnType<typeof createSetUserInfoAction>;
