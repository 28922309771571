import { PromiseStore } from './PromiseStore';

export const loadScript = (src: string) => {
    let scriptLoadingTask = PromiseStore.get('loadScript', src);
    const existingScript = document.querySelector(`script[src='${src}']`);
    if (existingScript && scriptLoadingTask) {
        return scriptLoadingTask;
    }

    scriptLoadingTask = new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = src;
        script.onload = () => resolve();
        script.onerror = reject;
        document.head.appendChild(script);
    });

    PromiseStore.set(scriptLoadingTask, 'loadScript', src);

    return scriptLoadingTask;
};

export const loadCss = (src: string) => {
    let cssLoadingTask = PromiseStore.get('loadCss', src);
    const existingCss =  document.querySelector(`link[href='${src}']`);
    if (existingCss && cssLoadingTask) {
        return cssLoadingTask;
    }

    cssLoadingTask = new Promise<void>((resolve, reject) => {
        const linkElement = document.createElement('link');
        linkElement.rel = 'stylesheet';
        linkElement.href = src;
        linkElement.onload = () => resolve();
        linkElement.onerror = reject;
        document.head.appendChild(linkElement);
    });

    PromiseStore.set(cssLoadingTask, 'loadCss', src);

    return cssLoadingTask;
};

