import { Reducer } from 'store';

export const initialState: {
    icid: string,
    email: string,
} = {
    icid: '',
    email: '',
} as const;

export type UserInfoState = typeof initialState;

export const UserInfoReducer: Reducer<UserInfoState> = (state = initialState, action) => {
    switch (action.type) {
        case '@USER_INFO/SET':
            return {
                ...state,
                icid: action.payload.icid,
                email: action.payload.email,
            };

        default:
            return state;
    }
};
