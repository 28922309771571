import { UserInfoAction } from './UserInfo/UserInfoActions';
import { UserInfoReducer } from './UserInfo/UserInfoReducer';
import { SubscriptionAction } from './Subscription/SubscriptionActions';
import { SubscriptionReducer } from './Subscription/SubscriptionReducer';
import { RecipientAction } from './Recipient/RecipientActions';
import { RecipientReducer } from './Recipient/RecipientReducer';
import { RecipientIntegrationAction } from './RecipientIntegration/RecipientIntegrationActions';
import { RecipientIntegrationReducer } from './RecipientIntegration/RecipientIntegrationReducer';
import { RecipientCountAction } from './RecipientCount/RecipientCountActions';
import { RecipientCountReducer } from './RecipientCount/RecipientCountReducer';

export type Action =
    | UserInfoAction
    | SubscriptionAction
    | RecipientAction
    | RecipientIntegrationAction
    | RecipientCountAction;

export type ApiRequest<T = undefined> = {
    readonly state: 'neverFetched' | 'isFetching' | 'valid' | 'outdated' | 'error';
    readonly data?: T;
};

export const needReload = (apiRequest: ApiRequest<unknown> | undefined) => {
    const { state } = apiRequest ?? { state: 'neverFetched' };
    return state === 'neverFetched' || state === 'outdated' || state === 'error';
};

const reducers = {
    userInfo: UserInfoReducer,
    subscription: SubscriptionReducer,
    recipient: RecipientReducer,
    recipientIntegration: RecipientIntegrationReducer,
    recipientCount: RecipientCountReducer,
};

type StateReducersMapObject = typeof reducers;
type StateKeys = keyof StateReducersMapObject;
export type State = { [key in StateKeys]: ReturnType<StateReducersMapObject[key]> };

export type Reducer<S = any, A = Action | { type: 'TEST' }> = (
    state: S | undefined,
    action: A
) => S

export type ReducersMapObject<S = any, A = Action | { type: 'TEST' }> = {
    [K in keyof S]: Reducer<S[K], A>
}

export type StateFromReducersMapObject<M> = M extends ReducersMapObject
    ? { [P in keyof M]: M[P] extends Reducer<infer S, any> ? S : never }
    : never

declare const $CombinedState: unique symbol;
interface EmptyObject {
    readonly [$CombinedState]?: undefined
}

export type CombinedState<S> = EmptyObject & S

export function combineReducers<S>(reducers: ReducersMapObject<S, any>): Reducer<CombinedState<S>>

export function combineReducers(reducers: ReducersMapObject) {
    const reducerKeys = Object.keys(reducers);
    const finalReducers: ReducersMapObject = {};
    for (let i = 0; i < reducerKeys.length; i++) {
        const key = reducerKeys[i];
        if (typeof reducers[key] === 'function') {
            finalReducers[key] = reducers[key];
        }
    }
    const finalReducerKeys = Object.keys(finalReducers);

    return function combination(
        state: StateFromReducersMapObject<typeof reducers> = {},
        action: Action,
    ) {

        let hasStateChanged = false;
        const nextState: StateFromReducersMapObject<typeof reducers> = {};
        for (let i = 0; i < finalReducerKeys.length; i++) {
            const key = finalReducerKeys[i];
            const reducer = finalReducers[key];
            const previousStateForKey = state[key];
            const nextStateForKey = reducer(previousStateForKey, action);
            if (typeof nextStateForKey === 'undefined') {
                const actionType = action && action.type;
                throw new Error(
                    `When called with an action of type ${actionType ? `"${String(actionType)}"` : '(unknown type)'}, the slice reducer for key "${key}" returned undefined. ` +
                    'To ignore an action, you must explicitly return the previous state. ' +
                    'If you want this reducer to hold no value, you can return null instead of undefined.',
                );
            }
            nextState[key] = nextStateForKey;
            hasStateChanged = hasStateChanged || nextStateForKey !== previousStateForKey;
        }
        hasStateChanged =
            hasStateChanged || finalReducerKeys.length !== Object.keys(state).length;
        return hasStateChanged ? nextState : state;
    };
}

export const reducer = combineReducers<State>(reducers);

export const initialState = reducer({} as State, {} as any);
