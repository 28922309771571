import { GetClientSubscriptionListModel } from 'services/ApiService/ClientSubscriptionList/ClientSubscriptionListApiClient';
import { ApiRequest, Reducer, mergeAllRecords, mergeRecords } from 'store';

export const initialState: {
    search: Record<string, ApiRequest<GetClientSubscriptionListModel[]>>,
    searchTotalPage?: number,
    items: Record<string, ApiRequest<GetClientSubscriptionListModel>>,
} = {
    search: {},
    items: {},
} as const;

export type SubscriptionState = typeof initialState;

export const SubscriptionReducer: Reducer<SubscriptionState> = (state = initialState, action) => {
    switch (action.type) {
        case '@SUBSCRIPTION/FETCH_SEARCH_LIST':
            return {
                ...state,
                search: mergeRecords(state.search, action.payload.searchKey, {
                    state: 'isFetching',
                }),
            } satisfies SubscriptionState;
        case '@SUBSCRIPTION/FETCH_SEARCH_LIST_SUCCESS':
            return {
                ...state,
                search: mergeRecords(state.search, action.payload.searchKey, {
                    state: 'valid',
                    data: action.payload.data,
                }),
                searchTotalPage: action.payload.totalPage,
                items: {
                    ...state.items,
                    ...action.payload.data
                        .reduce((prev, curr) => { prev[curr.id] = { state: 'valid', data: curr }; return prev; }, {} as typeof state.items),
                },
            } satisfies SubscriptionState;
        case '@SUBSCRIPTION/FETCH_SEARCH_LIST_FAILURE':
            return {
                ...state,
                search: mergeRecords(state.search, action.payload.searchKey, {
                    state: 'error',
                }),
            } satisfies SubscriptionState;

        case '@SUBSCRIPTION/FETCH_ONE_LIST':
            return {
                ...state,
                items: mergeRecords(state.items, action.payload.id, {
                    state: 'isFetching',
                }),
            } satisfies SubscriptionState;
        case '@SUBSCRIPTION/FETCH_ONE_LIST_SUCCESS':
            return {
                ...state,
                items: mergeRecords(state.items, action.payload.id, {
                    state: 'valid',
                    data: action.payload.data,
                }),
            } satisfies SubscriptionState;
        case '@SUBSCRIPTION/FETCH_ONE_LIST_FAILURE':
            return {
                ...state,
                items: mergeRecords(state.items, action.payload.id, {
                    state: 'error',
                }),
            };

        case '@SUBSCRIPTION/ADD_LIST':
        case '@SUBSCRIPTION/UPDATE_LIST':
        case '@SUBSCRIPTION/DELETE_LIST':
        case '@SUBSCRIPTION/OUTDATE_LIST':
            const items = action.type === '@SUBSCRIPTION/ADD_LIST' ? {} : mergeRecords(state.items, action.payload.id, {
                state: 'outdated',
            });
            return {
                ...state,
                items: items,
                search: mergeAllRecords(state.search, {
                    state: 'outdated',
                }),
            } satisfies SubscriptionState;

        default:
            return state;
    }
};
