import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader/WidgetLazyLoader';
import { configuration } from 'config/constants';
import React from 'react';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-splash-screen': { 'application-id': string };
        }
    }
}

export const SplashScreen = () => {
    return (
        <WidgetLazyLoader script={`${configuration.sharedWidgetBaseUrl}/widgets/sgwt-splash-screen/v4/sgwt-splash-screen.js`}>
            <sgwt-splash-screen
                application-id="client-subscription-list"
            />
        </WidgetLazyLoader>
    );
};
